<template>
  <div class="caseVue">
    <template>
      <header class="header" :style="`background-image: url(${imagePath})`" style="position: relative;">

      <div style="position: absolute;top: 150px;left: 250px;text-align: left;">
        <div>
          <h1 style="color : white;width: 500px;"><b>{{ emp.ename }}</b></h1>
          <p style="color : white; width: 500px;">{{ emp.description }}</p>
        </div>
      </div>
      </header>
    </template>

    
    <main>

      <div class="boss-content" style="margin-top: 50px;margin-bottom: 50px;">

        <div style=" margin: 0 auto;">
          <el-row :gutter="40">
            <el-col  :span="16">
              <div style="height: 1px;width: 100%"></div>
              <div  :class="`html ${path == 'quarterly' ? 'quarterly' : ''}`"
                v-html="datas.content">
              </div>
            </el-col>
            <el-col  :span="8">
              <div style="box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);">
                <div>
                  <div style="height: 100px;width: 300px;">
                    
                    <img style="width: 100%;height: 100%;object-fit: contain;" :src="emp.logoPath ? $imgUrl + emp.logoPath : ''" />
                    
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <h4>{{ emp.ename }}</h4>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>地区：{{ emp.eregion }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>年份：{{ emp.ctime }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>行业：{{ emp.scene }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>场景：{{ emp.scene }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    产品：<el-tag v-for="tag in emp.tag" :key="tag" :type="tag" style="line-height: 20px;height: 20px;padding-left: 3px;padding-right: 3px;">{{
                      tag
                    }}</el-tag>
                  </div>
                  <div >
                    <el-button class="image-button" style="width: 100%;color: white;">方案咨询 &nbsp;&nbsp; →</el-button>
                  </div>
                  
                </div>
              </div>
            </el-col>
          </el-row>
        </div>





        <!-- <b-container fluid="xl">
          <b-row class="mb-4">
            <b-col md="12">
              <div style="width: 70%;float:left" :class="`html ${path == 'quarterly' ? 'quarterly' : ''}`"
                v-html="datas.content">
              </div>
              <div style="width: 300px; float:right;box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);">
                <div>
                  <div style="height: 100px;width: 300px;">
                    
                    <img style="width: 100%;height: 100%;object-fit: contain;" :src="emp.logoPath ? $imgUrl + emp.logoPath : ''" />
                    
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <h4>{{ emp.ename }}</h4>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>地区：{{ emp.eregion }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>年份：{{ emp.ctime }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>行业：{{ emp.scene }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    <p>场景：{{ emp.scene }}</p>
                  </div>
                  <div style="margin-left: 20px;margin-right: 20px;">
                    产品：<el-tag v-for="tag in emp.tag" :key="tag" :type="tag" style="line-height: 20px;height: 20px;">{{
                      tag
                    }}</el-tag>
                  </div>
                  <div >
                    <el-button class="image-button" style="width: 100%;color: white;">方案咨询 &nbsp;&nbsp; →</el-button>
                  </div>
                  
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container> -->
 


      </div>

    </main>
  </div>
</template>

<script>
// import jikan from "../assets/jikan.js";
export default {
  //import引入的组件
  components: {},
  // props: ["id", "path"],
  props: {
    id: String,
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      datas: {},
      emp: {},
      defaultActive: 1,
      imagePath: ''
    };
  },
  //方法集合
  methods: {
    removeHtmlStyle(html) {
      var rel = /p style\s*?=\s*?(['"])[\s\S]*?\1/g;
      var newHtml = "";
      if (html) {
        newHtml = html.replace(rel, "p");
      }
      console.log(newHtml);
      return newHtml;
    },
    async getData(id) {
      console.log("id", id);
      if (!id) {
        return false;
      }
      let _this = this;
      await _this.$http
        .get(`${this.path == "quarterly" ? "/quarterly" : ""}/${id}`)
        .then((e) => {
          console.log(e);
          if (e.status == 200) {
            _this.datas = e.data;
            // _this.datas.content = _this.removeHtmlStyle(e.data.content);
          }
        });
    },
    async getEmp(id) {
      if (!id) {
        return false;
      }
      let _this = this;
      await _this.$http
        .get(`/getEmp/${id}`)
        .then((e) => {
          if (e.status == 200) {
            this.emp = e.data.data;
            let tag = this.emp.product.split("、");
            this.imagePath = this.$imgUrl + this.emp.imgPath.replaceAll('\\', '/');
            this.emp.tag = tag;
          }
        });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData(this.id);
    this.getEmp(this.id);
  },
  watch: {
    id(val) {
      this.getData(val);
      this.getEmp(val);
    },
  },
};
</script>
<style lang="scss">
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

// .caseVue {
//   .header {
//     height: 20rem !important;
//     // background: linear-gradient(90deg, #2d5fcd 0, #7b5ab6 100%), #2839a0;
//     // background-image:url(imagePath)
//   }

//   main {
//     margin-top: 2rem;
//   }
// }

.html.quarterly {
  img {
    margin: 2rem 0;
    width: 100%;
    text-align: center;
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.html {
  img {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .html {
    img {
      width: 100%;
    }
  }
}
</style>